import { googleSpreadsheetAPI } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const READ_ROWS_REQUEST = 'app/sheets/READ_ROWS_REQUEST';
export const READ_ROWS_SUCCESS = 'app/sheets/READ_ROWS_SUCCESS';
export const READ_ROWS_ERROR = 'app/sheets/READ_ROWS_ERROR';

export const APPEND_ROW_REQUEST = 'app/sheets/APPEND_ROW_REQUEST';
export const APPEND_ROW_SUCCESS = 'app/sheets/APPEND_ROW_SUCCESS';
export const APPEND_ROW_ERROR = 'app/sheets/APPEND_ROW_ERROR';

// ================ Reducer ================ //

const initialState = {
  readRowsInProgress: false,
  readRowsError: null,
  appendRowsInProgress: false,
  appendRowsError: null,
};

const sheetsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case APPEND_ROW_REQUEST:
      return {
        ...state,
        appendRowInProgress: true,
        appendRowError: null,
      };
    case APPEND_ROW_SUCCESS:
      return {
        ...state,
        appendRowInProgress: false,
        appendRowError: null,
      };
    case APPEND_ROW_ERROR:
      return {
        ...state,
        appendRowInProgress: false,
        appendRowError: payload,
      };

    case READ_ROWS_REQUEST:
      return {
        ...state,
        readRowsInProgress: true,
        readRowsError: null,
      };
    case READ_ROWS_SUCCESS:
      return {
        ...state,
        readRowsInProgress: false,
        readRowsError: null,
      };
    case READ_ROWS_ERROR:
      return {
        ...state,
        readRowsInProgress: false,
        readRowsError: payload,
      };

    default:
      return state;
  }
};

export default sheetsReducer;

// ================ Action creators ================ //

export const appendRowRequest = () => ({ type: APPEND_ROW_REQUEST });
export const appendRowSuccess = () => ({ type: APPEND_ROW_SUCCESS });
export const appendRowError = e => ({ type: APPEND_ROW_ERROR, error: true, payload: e });

export const readRowsRequest = () => ({ type: READ_ROWS_REQUEST });
export const readRowsSuccess = () => ({ type: READ_ROWS_SUCCESS });
export const readRowsError = e => ({ type: READ_ROWS_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const appendRow = (id, row) => (dispatch, getState, sdk) => {
  dispatch(appendRowRequest());

  return googleSpreadsheetAPI
    .append({ id, row })
    .then(response => {
      dispatch(appendRowSuccess(response));
      return response;
    })
    .catch(e => dispatch(appendRowError(storableError(e))));
};

export const readRows = id => (dispatch, getState, sdk) => {
  dispatch(readRowsRequest());

  return googleSpreadsheetAPI
    .read({ id })
    .then(response => {
      dispatch(readRowsSuccess(response));
      return response;
    })
    .catch(e => dispatch(readRowsError(storableError(e))));
};