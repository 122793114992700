import React from 'react';
import { IconBack, IconCouch, IconHome, ResponsiveImage } from '../../components';

import css from './ListingPage.module.css';
import { getAddressCity, getMainAddress } from '../../util/data';

export const industryIcon = industry => {
  if (industry === 'architecture') {
    return <IconHome className={css.titleIndustryIcon} />;
  } else if (industry === 'interior-design') {
    return <IconCouch className={css.titleIndustryIcon} />;
  }

  return null;
};

const SectionTitle = props => {
  const { title, publicData, isCompanyUser, history, imageAlt, firstImage } = props;
  const addressFromPublicData = publicData?.address;
  const googleMapsUrl = publicData?.googleMapsUrl;
  const address = getMainAddress(addressFromPublicData, googleMapsUrl);
  const city = getAddressCity(addressFromPublicData);

  const industry = publicData?.industry;
  return (
    <div className={css.sectionTitleContent}>
      <div className={css.iconBack} onClick={() => history.goBack()}>
        <IconBack />{' '}
      </div>
      <div className={css.sectionTitle}>
        <div className={css.sectionTitleLeft}>
          <ResponsiveImage
            rootClassName={css.sectionTitleImage}
            alt={imageAlt}
            image={firstImage}
            variants={['listing-card']}
          />
          <div>
            <h1 className={css.title}>{title}</h1>
            <div className={css.sectionTitleDescription}>
              <div>{city + ', '}</div>
              <div> {industry}</div>
            </div>
          </div>
        </div>

        {/* {isCompanyUser ? industryIcon(industry) : null} */}
        {industryIcon(industry)}
      </div>
    </div>
  );
};

export default SectionTitle;
